$base-theme:Bootstrap;
$skin-name:keen_theme;
$swatch-name:Bootstrap;
$border-radius: 0.25rem;
$primary: #052F5F;
$accent: #005377;
$secondary: #005377;
$info: #005377;
$success: #06A77D;
$warning: #F7B936;
$error: #e1525f;
$body-bg: #ffffff;
$body-color: #052F5F;
$component-bg: #ffffff;
$component-color: #052F5F;
$card-cap-bg: #f7f7f9;
$card-cap-color: #052F5F;
$series-a: #0275d8;
$series-b: #5bc0de;
$series-c: #5cb85c;
$series-d: #f0ad4e;
$series-e: #e67d4a;
$series-f: #d9534f;
