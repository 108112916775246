.aero-patient-tabs {
  .k-text-disabled {
    opacity: 1;
  }

  .aero-patient-tab {
    height: 100%;
    div {
      width: 100%;
    }
  }
}

.aero-patient-chart {
  .k-grid td {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .k-text-disabled {
    opacity: 1;
  }
}
