$white: #ffffff;
$green: #06A77D;
$dark_blue: #052F5F;
$red: #FF0000;

.panelBarWrapper, .paymentBarItemWrapper, .saveButton {
  margin: 20px;
  span {
    display: block;
  }
}
.panelBarWrapper {
  width: 100%;
  .maxSubscriptionWarning {
    i {
      padding: 5px 0 21px 10px;
      margin-right: 15px;
    }
  }
}
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #E4EDFF;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}
.StripeElement--invalid {
  border-color: #fa755a;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.poweredBy{
  height: 30px;
  position: relative;
  left: 0;
  top: 10px;
}
.toast{
  position: absolute;
  top: 40px;
  right: 20px;
}
.responsibleForPaymentWrapper{
  .instructions{
    margin-bottom: 5px;
  }
  .comboBox{
    width: 250px;
  }
}

// Start Billing UI

.patient-billing {
  h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  &__top-section {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
  }

  &__subscription {
    background-size: cover;
    background-position: center;
    background-color: #005377ee;
    border-radius: 8px;
    height: calc(100% - 16px);
    padding: 20px;
    position: relative;

    @media(max-width: 992px) {
      margin-bottom: 20px;
    }

    * {
      color: $white;
    }

    h2 {
      color: $white;
      margin-bottom: 10px;
    }
  }

  &__subscription-card-wrap {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
  }

  &__subscription-price {
    font-size: 28px;
    margin-bottom: 0;

    span {
      font-size: 14px;
    }
  }

  &__subscription-frequency {
    font-size: 15px;
    font-weight: 700;
  }

  &__pause {
    display: flex;
    align-items: flex-end;

    input {
      font-size: 12px;
      color: inherit;
    }

    button {
      background-color: $green;
      color: $white;
    }
  }

  &__outstanding-balance {
    padding: 20px;
    height: calc(100% - 15px);
    border: 1px solid #E4EDFF;
    border-radius: 8px;
    position: relative;

    @media(max-width: 992px) {
      margin-bottom: 20px;
    }
  }

  &__add-payment {
    width: 100%;

    label {
      font-size: 14px;
      font-weight: 600;
    }

    .poweredBy {
      display: none;
    }
  }

  &__add-card {
    margin-top: 20px;
  }

  &__scheduled-date, &__schedule-paused {
    font-size: 12px;
    font-weight: 700;
    margin: auto 0 0;
  }

  &__schedule-paused {
    color: $red;
    font-size: 15px;
  }

  &__floating-icon {
    position: absolute;
    right: 38px;
    top: 16px;
    text-align: center;
    border: none;
    font-size: larger;

    &.credit-card {
      border: none;
      background-color: rgba(#F7B936, .3);
      padding: 4px;
    }
  }

  &__make-payment {
    color: $white;
    background-color: #052F5F;
    border: none;
    outline: none;
    margin-top: auto;
    display: inline-block;
    width: auto;
  }

  &__payment-info-wrap {
    display: flex;
  }

  &__payment-method {
    padding: 25px 15px;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(72,167,220,1) 100%);
    border-radius: 8px;
    display: none;

    &.ach {
      background: #F7B936;
      border: 1px solid #052F5F;

      > * {
        color: #052F5F;
      }
    }

    &.active {
      display: block;
    }
  }

  &__payment-info-actions {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    button {
      margin-bottom: 5px;
      border-radius: 50%;
      outline: none;
      border: 1px solid #E4EDFF;
      background: $white;

      &:first-of-type {
        background: #052F5F;
      }
    }
  }

  &__add-payment-actions {
    margin-top: 10px;
    display: flex;

    button {
      flex-grow: 1;

      &:first-of-type {
        margin-right: 10px;
      }
    }
  }

  &__card-number {
    color: $white;
    display: flex;
    justify-content: flex-start;
    font-size: 13px;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
    max-width: 200px;

    span {
      letter-spacing: 2px;
      line-height: 1;

      &:last-of-type {
        letter-spacing: inherit;
        position: relative;
        top: -4px;
        font-size: 16px;
      }
    }
  }

  &__bank-name,
  &__brand-text {
    color: $white;
    font-weight: 700;
  }

  &__payment-details {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    color: $white;
    font-size: 12px;
    font-weight: 600;
    margin-top: 20px;

    &.routing {
      margin-top: 0;
    }

    .card-label {
      opacity: .5;
      font-size: 10px;
      font-weight: 400;
    }

    p {
      margin: 0;
    }
  }

  &__card-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    button {
      height: 6px;
      margin-right: 4px;
      outline: none;
      border: none;
      background: #052F5F;
      opacity: .15;
      border-radius: 6px;
      width: 6px;

      &:hover {
        opacity: .5;
      }

      &.active {
        opacity: 1;
        width: 30px;
      }
    }
  }

  &__billing-history {
    .k-dialog-wrapper {
      .k-overlay {
        z-index: 0;
      }
    }
    .k-hierarchy-cell {
      &.can-expand {
        cursor: pointer;
      }
    }
    .confirmation-message {
      margin: 25px;
      text-align: center;
    }
    .mark-paid {
      margin-left: 5px;
      span {
        margin-left: 5px;
      }
    }
    .refund-amount{
      margin-left: 5px;
    }
  }
}

.billing-history-status-button {
  margin-left: 8px;

  &.refund {
    background-color: $dark_blue;
    border-color: $dark_blue;
  }
}

.refund-input {
  display: inline-block;
  margin-left: 8px;
  input {
    font-size: 10px;
    width: 100px;
    margin-right: 5px;
  }
  button {
    border: none;
    text-align: center;
    padding: 1px;
    background-color: $green;
    color: $white;
    border-color: $green;

    span {
      border: none !important;
      padding: 0 !important;
    }
  }
}
