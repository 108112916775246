$keen-red: #E1525F;
$keen-yellow: #F7B936;
$keen-green: #06A77D;

.keenhp-price-box {
  background-color: #fff;
  padding: 0px 24px 24px 24px;
  border-radius: 5px;

  &.selectable {
    cursor: pointer;
    &.MONTH {
      &:hover {
        background-color: lighten($keen-green, 30);
      }
    }
    &.QUARTER {
      &:hover {
        background-color: lighten($keen-yellow, 20);
      }
    }
    &.ANNUAL {
      &:hover {
        background-color: lighten($keen-red, 20);
      }
    }
    &.selected {
      &.MONTH {
        background-color: lighten($keen-green, 40);
        &:hover {
          background-color: lighten($keen-green, 30);
        }
      }
      &.QUARTER {
        background-color: lighten($keen-yellow, 25);
        &:hover {
          background-color: lighten($keen-yellow, 20);
        }
      }
      &.ANNUAL {
        background-color: lighten($keen-red, 25);
        &:hover {
          background-color: lighten($keen-red, 20);
        }
      }
    }
  }

  .k-widget .k-grid {
    .k-grid-header {
      background-color: #fff;
    }
  }

  .ribbon {
    width: 100%;
    $default-right: 10px;
    $default-top: -3px;
    $default-color: #2ca7d8;
    $default-width: 100%;
    $default-height: 36px;

    right: var(--right, $default-right);
    top: var(--top, $default-top);

    filter: drop-shadow(2px 3px 2px rgba(black, 0.5));

    > .content {
      color: white;
      font-size: 1.25rem;
      text-align: center;
      font-weight: 400;
      padding: 30px 4px;

      &.MONTH {
        background: var(--color, $keen-green) linear-gradient(45deg, rgba(black, 0) 0%, rgba(white, 0.25) 100%);
      }
      &.QUARTER {
        background: var(--color, $keen-yellow) linear-gradient(45deg, rgba(black, 0) 0%, rgba(white, 0.25) 100%);
      }
      &.ANNUAL {
        background: var(--color, $keen-red) linear-gradient(45deg, rgba(black, 0) 0%, rgba(white, 0.25) 100%);
      }


      clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 100%);

      width: var(--width, $default-width);
      min-height: var(--height, $default-height);

      transition: clip-path 0.35s;
    }

    &.slant-up > .content {
      clip-path: polygon(0 0, 100% 0, 100% calc(100% - 12px), 50% calc(100% - 6px), 0 100%);
    }

    &.slant-down > .content {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 6px), 0 calc(100% - 12px));
    }

    &.down > .content {
      clip-path: polygon(0 0, 100% 0, 100% calc(100% - 18px), 50% 100%, 0 calc(100% - 18px));
    }

    &.up > .content {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 18px), 0 100%);
    }

    &.check > .content {
      clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), 40% 100%, 0 calc(100% - 12px));
    }
  }
}
