* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: #f8f8f8 !important;
}
html, body, #root, .App, .container-fluid {
  height: 100%;
  width: 100%;
  padding: 0px !important;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

.container-fluid {
  padding: 0px;
}

:focus {
  outline: none !important;
  box-shadow: none !important;
}

.mobile-grid-wrapper {
  width: 100%;
  overflow: auto;
  .k-grid {
    min-width: 600px;
  }
}

.row {
  margin: 0px !important;
}

// override some variables from the themes
$table-cell-padding: 0.5em;
$modal-header-padding-y: 0.5em;

$navbar-padding-y: 0em;
$menu-item-padding-y: 0em;

$badge-font-weight: 400;
$font-family-base: 'Catamaran', sans-serif;

$border-color: #E4EDFF;
$dark-primary: #052F5F;
$keen-red: #E1525F;
$keen-yellow: #F7B936;
$keen-green: #06A77D;

// default to bootstrap theme, potentially in the future we can allow people to use different ones,
// or we could implement a dark mode by importing a different theme in the app.
@import "themes/keen";
@import "~@progress/kendo-theme-bootstrap/dist/all.scss";
// @import "~@progress/kendo-theme-material/scss/all.scss";
@import "~bootstrap/scss/bootstrap";
// @import "assets/fontello-icons/css/fontello.css";

// These have to go after the import above
// set the font family
body {
  font-family: $font-family-base;
}
.navbar {
  border-bottom: 1px $border-color solid;
  padding: 12px 20px;
  z-index: 2;
  background-color: $white;
}

.navbar-brand {
    padding: 0;
    font-size: inherit;
}

.k-dialog-wrapper, .k-list-container {
  z-index: 9999999;
}

// Typography
h1, .h1 {
  font-size: 24px;
}

// set the logo text color to match the theme color
.logo-text {
  color: $primary !important;
  font-weight: bold;
}

:root {
  --amplify-primary-color: #052F5F;
  --amplify-primary-tint: #052F5F;
  --amplify-primary-shade: #052F5F;
  --amplify-secondary-color: #052F5F;
  --amplify-secondary-tint: #052F5F;

  // note colors -- these will change, different now for representation of what they can be
  --keenhp-sms: cyan;
  --keenhp-phone-call: blue;
  --keenhp-lab: green;
  --keenhp-document: magenta;
  --keenhp-rx: purple;
}

$dark-primary: #052F5F;
$light-grey: #D4D4DF;

.k-textbox {
  width: 100%;
}
// Billing Card
.powered-by {
  position: relative;
  height: 15px;
  margin: 5px 0 10px 0;
}

.btn {
  display: inherit !important;
}

.k-switch {
  width: 40px;
  height: 20px;
}

.k-switch-off .k-switch-container {
  background-color: #ccc;
}
.k-switch-on .k-switch-container {
  background-color: $dark-primary;
}

.k-switch-on .k-switch-handle {
  left: calc(100% - 16px);
}

.k-switch-container {
  padding: 1px 3px;
  width: 40px;
}

.k-switch-handle {
  width: 16px;
  height: 16px;
}


.dark-toast {
  background-color: $dark-primary;
  color: $light-grey;
}

.k-grid-header {
  padding: 0px;
}

.familyHistoryGridClass {
  thead {
    tr {
      th:not(:first-child):not(:last-child) {
        text-align: center;
      }
    }
  }
  .k-grid-edit-cell {
    text-align: center;
  }
}
.keenhp-multi-patient-setup {
  width: 100%;
  overflow-x: auto;
}

// hide wizard step text in mobile view
.k-step-text {
  @media(max-width: 767px) {
    display: none;
  }
}
.k-step-current {
  .k-step-text {
    display: block;
    position: absolute;
    width: 100%;
    top: 32px;
  }
}

.k-maskedtextbox {
  width: 100% !important;
}

.text-dark-grey {
  color: #696d79 !important;
}

.text-grey {
  color: #9095a5 !important;
}

.simple-payment {
  height: 100%;
  @media(min-width: 767px) {
    width: 75%;
    margin: auto;
  }
  @media(min-width: 1200px) {
    width: 50%;
    margin: auto;
  }
}
.h-100-not-mobile {
  @media(min-width: 767px) {
    height: 100%
  }
}
.margin-top-mobile {
  @media(max-width: 767px) {
    margin-top: 16px !important;
  }
}
.small-text-on-mobile {
  @media(max-width: 767px) {
    font-size: 12px !important;
  }
}

.state-selector-field {
  .k-form-field {
    display: flex;
  }
}
